<template>
    <!--错误页面-->
  <div class="container">
    <div v-if="errorCode === 404" class="error-404">
    	<div class="container-404">
    		<p class="text">很抱歉，您访问的页面不存在…</p>
    	</div>
    </div>
    <div v-else class="error-500">
    	<div class="container-500">
        <img class="error_page" src="../assets/error_page.png" alt="">
    		<p class="text-500">哎呀！服务器出错了…</p>
    		<div class="btn-container">
    			<a class="button" @click="refresh">刷新页面</a>
    			<a  class="button" @click="goHome()">返回首页</a>
    		</div>
    	</div>
    </div>
    <!-- <h1 v-else>An error occurred</h1>
    <nuxt-link to="/">Home page</nuxt-link> -->
  </div>
</template>

<script>
export default {

  data() {
    return {
      errorCode: 500 //错误状态
    }
  },
  mounted() {
    this.errorCode = this.$route.query.errorCode
  },
  methods:{
  	refresh(){
  		window.location.reload();
  	},
    goHome() {
      this.$router.push('/home')
    }
  }
}
</script>
<style scoped>
.container{
	height: 100%;
}
.error-404{
	background-color: #f7f8f3;
	height:100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.container-404{
	width:610px;
	height:500px;
	position: relative;
}
.four{
	width:299px;
	height:126px;
	position: absolute;
	left: 156px;
	top:310px;
}
.four_moon{
	width:73px;
	height:60px;
	position: absolute;
	left: 0;
	top:245px;
}
.four_planet{
	width:266px;
	height:265px;
	position: absolute;
	left: 110px;
	top:0;
}
.four_samll_planet{
	width:82px;
	height:60px;
	position: absolute;
	right: 84px;
	top:0;
}
.text{
	width:100%;
	height:32px;
	line-height: 32px;
	position: absolute;
	bottom: 0;
	left:0;
	font-size: 20px;
	color: #45526E;
	text-align:center;
}
.error-500{
	background-color: #fff;
	height:100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.container-500{
	width:660px;
	height:415px;
	position: relative;
}
.text-500{
	width:100%;
	height:32px;
	line-height: 32px;
	font-size: 20px;
	color: #45526E;
	text-align:center;
	margin-top:20px;
}
.btn-container{
	display: flex;
	height:34px;
	align-items: center;
	justify-content: space-between;
	padding:0 150px;
	margin-top:40px;
}
.button{
	width:112px;
	height:32px;
	border: 1px solid #008BE0;
	background-color: #fff;
	text-decoration: none;
	font-size: 14px;
	color: #008BE0;
	line-height: 32px;
	text-align: center;
	transition: all .3s;
	border-radius: 2px;
	cursor:pointer;
}
.button:hover{
	background-color: #008BE0;
	color:#fff;
}
</style>
